
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import store from '@/store';
import { auth } from '@/store/namespaces';
import AuthTypes from '@/store/auth/methods/auth.methods';
import { User } from '@/modules/auth/interfaces/user.interface';
import { clearUser } from '../../emptyStates/user.emptyState';


@Component({
    components: {
        SvgIcon,
    },
})
export default class RegisterSuccess extends Vue {


    mounted(){
        
    }

    @auth.Getter(AuthTypes.getters.GET_REGISTER_USER_DATA)
    userData!: Partial<User>;
    @auth.Getter(AuthTypes.getters.GET_REGISTER_PUBLIC_CODE)
    publicCode!: string;
}
